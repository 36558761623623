import { lazy } from "react";

const Signin = lazy(() => import("./Signin"));

const ForgotPassword = lazy(() => import("./ForgotPassword"));
const Error = lazy(() => import("./Error"));
const newme_sessionsRoutes = [  
  {
    path: "/signin",
    component: Signin
  },
  {
    path: "/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/error",
    component: Error
  }
];

export default newme_sessionsRoutes;
