
import { lazy } from "react";

const Adduser = lazy(() => import("../users/Adduser"));
const ManageUser = lazy(() => import("../users/ManageUser"));
const ManageAdmin = lazy(() => import("../users/ManageAdmin"));
const Newmechat = lazy(() => import("./Newmechat/AppChat"));
const PatientSchedule = lazy(() => import("./PatientSchedule"));
const Prescription = lazy(() => import("./Prescription"));
const Prescrippdf = lazy(() => import("./prescrippdf"));
const Userprofile = lazy(() => import("./Userprofile"));
const PatientView = lazy(() => import("./PatientView"));
const AdduserInsert = lazy(() => import("../users/AdduserInsert"));
const userRoutes = [  
  {
    path: "/adduser",
    component: Adduser
  },
  {
    path: "/adduserinsert",
    component: AdduserInsert
  },
  {
    path: "/manageuser",
    component: ManageUser
  },
  {
    path: "/manageadmin",
    component: ManageAdmin
  },
  {
    path: "/Newmechat",
    component: Newmechat
  },
  {
    path: "/patient",
    component: PatientSchedule
  },
  {
    path: "/prescription",
    component: Prescription
  },
  {
    path: "/prescripppdf",
    component: Prescrippdf
  },
  {
    path: "/userprofile",
    component: Userprofile
  },
  {
    path: "/dashboard",
    component: PatientView
  },
];

export default userRoutes;
