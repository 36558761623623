import axios from "axios";
import localStorageService from "./localStorageService";
import history from "@history";
import {
  NotificationManager,

} from "react-notifications";

class JwtAuthService {
  
  user = {
    userId: "1",
    role: 'ADMIN',
    displayName: "Watson Joyce",
    email: "watsonjoyce@gmail.com",
    photoURL: "/assets/images/face-7.jpg",
    age: 25,
    token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
  }
  state = {
    username : '',
    password: ''
  };
  loginWithEmailAndPassword = (value) => {

   

 

    return new Promise((resolve, reject) => {

   

        // resolve(this.user);

         axios.post('http://newmefitapi.rcktechiees.com/api/v1/auth/login',value)

        .then(response => {

        this.setSession(response.data.token);
        localStorage.setItem('userid',response.data.userId)
        localStorage.setItem('roleid',response.data.roleId)
        this.setUser(response);

      


        NotificationManager.success(
          "Success",
          "Login Success",
          1000
        );

        setTimeout(() => {
          history.push({
            pathname: "/userprofile",
          })
        }, 1000);

      

        return response;

      })

   

 

    .catch(error => {

  
      NotificationManager.error(
        "Failed",
        "Login Failed",
        1000
      );

     
      // history.push({

      //   pathname: "/adduser"

      // });

      console.error('There was an error!', error);

     
      

  });

})

  };
  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}


export default new JwtAuthService();
